import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import {useState} from 'react'

var firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET ,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID  ,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID
};

initializeApp(firebaseConfig);

export const requestPermission = ()=> {
  // console.log('Requesting permission...');
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      // console.log('Notification permission granted.');
    }
  })
  .catch(err=> {
    // console.log('Error during permission request')
  });
}

const messaging = getMessaging();

export const requestForToken = async () => {
  return getToken(messaging, { vapidKey: process.env.REACT_APP_VAPIDKEY })
    .then((currentToken) => {
      if (currentToken) {
        // console.log('current token for client: ', currentToken);
        // localStorage.removeItem('fbToken')
         
        localStorage.setItem('fbToken',currentToken)
        sessionStorage.setItem('fbtoken',currentToken);
        return currentToken;
      } else {
        // console.log('No registration token available. Request permission to generate one.');
        return false;
      }
    })
    .catch((err) => {
      // console.log('An error occurred while retrieving token. ', err);
      return false;
    });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {    
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
