import axios from "axios";
import Auth from "./Auth";
import Swal from 'sweetalert2';
import history from "./History";

export default class Intercept {
  //class Intercept extends Component {
  static addAuthToken = true;
  static debounceTimeout = null;
  static setRequestInterceptors() {
    // Add a request interceptor
    axios.interceptors.request.use(
      async function (config) {
       
        // Do something before request is sent
        //Pass loggedInAsClient and uid value if superAdmin try to act as normal user else the value will be blank
        config.headers = {
            Accept: "application/json",
            "Content-Type": "application/json",            
          loggedInAsClient: localStorage.getItem("loggedInAsClient")
            ? localStorage.getItem("loggedInAsClient")
            : "",
          uid: localStorage.getItem("loggedInAsClientUserId")
            ? localStorage.getItem("loggedInAsClientUserId")
            : "",
        };
        if (Intercept.addAuthToken === true) {
          const accessToken = await Auth.getToken();
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        // config.headers['Access-Control-Allow-Origin'] = '*'; // Allow requests from any origin
        // config.headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE, OPTIONS'; // Allow these HTTP methods
        // config.headers['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept, Authorization'; // Allow these headers

        return config;
      },
      function (error) {
        // Do something with request error
        //console.log(error, 1);
        return Promise.reject(error);
      }
    );
  }

  static setResponseInterceptors() {
    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        // console.log(response,'response')
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        //console.log(error.response);
        if (error.response) {
          let userInformation = Auth.userInformationAndSettings["user_information"];
          if (error.response.status === 401) {
            //Auth.logout(function () {});
            //history.push("login");
          } else if (error.response.status === 422) {
            let errors = error.response.data.errors;
            let errorHtml =
              '<ul style="text-align: center;list-style-type: none">';

            Object.keys(errors).forEach((errorKey) => {
              errors[errorKey].forEach((errorMessage) => {
               // console.log(errorMessage);
                var errorInputField = "";
                if (document.getElementById(errorKey) !== null) {
                  document.getElementById(errorKey).focus();
                  errorInputField =
                    document.getElementById(errorKey).parentElement
                      .lastElementChild;
                  if(errorInputField.length > 0){
                    errorInputField.innerHTML = errorMessage;
                    errorHtml += "<li>" + errorMessage + "</li>";
                  }
                  else{
                    Swal.fire({
                      icon: "error",
                      title: "Oops!",
                      html: errorMessage,
                    });
                  }

                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Oops!",
                    html: errorMessage,
                  });
                  //alert(errorMessage);
                }
              });
            });

            errorHtml += "</ul>";
            const wrapper = document.createElement("div");
            wrapper.innerHTML = errorHtml;

            // Swal.fire({
            //   icon: "error",
            //   title: "The given data was invalid.",
            //   html: wrapper
            // });
          } else if (error.response.status === 403) {
            //html: "You do not have permission to perform this operation",

            Swal.fire({
              icon: "warning",
              title: "No Permission!",
              showCancelButton: userInformation.loginasClient === 1? true : false, //to be shown only if Admin has logged in as client
              confirmButtonText: userInformation.loginasClient === 1? "Continue as Client" : "Go to dashboard",
              cancelButtonText: userInformation.loginasClient === 1? "Continue as Admin" : "",
              html: "Looks like you are logged in as a client, therefore performing this operation would not work.",
              allowOutsideClick: false,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                // history.push("dashboard");
          
                // window.location.hash = "#/dashboard"; //for redirection to dashboard
                window.location.href = "/";
                
                // window.location.reload();
                // this.context.state.isSuperAdmin=0
              } else if (result.isDismissed) {
                localStorage.setItem("storeFlag", "false"); //for restoring admin condition
                localStorage.removeItem("loggedInAsClient");
                localStorage.removeItem("loggedInAsClientUserId");
                localStorage.removeItem("activeStoreID");
                localStorage.removeItem("activeShipStationStoreID");
                localStorage.removeItem("activeStoreName");
                localStorage.removeItem("currentStoreName");
                localStorage.removeItem("order");
                
                window.location.href = "/";
              }
            });
            //console.log(error.response);
          } else if (error.response.status === 500) {
            console.log('Internal Server error')
            Swal.fire({
              icon: "error",
              title: "Oops!",
              html: error.response.statusText,
            });
            //console.log(error.response);
          } else if (error.response.status === 400) {
            // don't do anything
          } else {
            console.log('Server error occurred.')
            Swal.fire({
              icon: "error",
              title: "Oops!",
              html:
                "Server error occurred. Status code: " + error.response.status,
            });
          }
        } else {
          console.log('Server error occurred.')
          // Swal.fire({
          //   icon: "error",
          //   title: "Oops!",
          //   html: "Server error occurred.",
          // });
        }
        // console.log(error, "error")
        if(error.response.status !== 403)
        return Promise.reject(error);
      }
    );
  }

  static debounceAPICall(func, delay) {
    return function() {
      const context = this;
      const args = arguments;
      clearTimeout(Intercept.debounceTimeout);
      Intercept.debounceTimeout = setTimeout(() => {
        Intercept.loading = false; // Reset loading indicator if there's no new API call within delay time
        func.apply(context, args);
      }, delay);
    };
  }

  static debouncedAPICall = Intercept.debounceAPICall((config) => {
    // Only make the API call if loading indicator is false
    if (!Intercept.loading) {
      axios(config);
    }
  }, 300); // Adjust delay as needed
}