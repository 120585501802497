import axios from "axios";
import * as moment from "moment-timezone";
import Intercept from "./Intercept";
import Swal from "sweetalert2";
import history from "./History";
export default class Auth {
  static access_token = null;
  //static access_token = null;

  static userInformationAndSettings = {};
  
  static login(_username, _password, successCallBack, errorCallBack) {
   
    Intercept.addAuthToken = false;
      axios
      .post(process.env.REACT_APP_API_URL + "oauth/token", {
        grant_type: "password",
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        username: _username,
        password: _password,
        scope: "",
      })
      
      .then((response) => {
        Intercept.addAuthToken = true;
       
        const access_token = response["data"]["access_token"];
        const refresh_token = response["data"]["refresh_token"];
        const expires_in = response["data"]["expires_in"];
        if (access_token && access_token !== "") {
          Auth.saveTokenResponse(access_token, refresh_token, expires_in);
          successCallBack();
        } else {
          //console.log(response);
          errorCallBack("Unable to get access token from server");
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            errorCallBack("Username or password is not correct.");
          } else if (error.response.status === 401) {
            errorCallBack("User is no longer active. Please contact admin.");
          } else {
            errorCallBack(error.response.data.message);
          }
        }
      })
      .finally(() => {
     
      
        Intercept.addAuthToken = true;
        
      
      }); 
  }
  static loginWithOtp(_username, _otp, successCallBack, errorCallBack) {
   
    Intercept.addAuthToken = false;
      axios
      .post(process.env.REACT_APP_API_URL + "oauth/token", {
        grant_type: "otp_grant",
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        username: _username,
        otp: _otp,
        scope: "",
      })
      
      .then((response) => {
        Intercept.addAuthToken = true;
       
        const access_token = response["data"]["access_token"];
        const refresh_token = response["data"]["refresh_token"];
        const expires_in = response["data"]["expires_in"];
        if (access_token && access_token !== "") {
          Auth.saveTokenResponse(access_token, refresh_token, expires_in);
          successCallBack();
        } else {
          //console.log(response);
          errorCallBack("Unable to get access token from server");
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            errorCallBack("Username or password is not correct.");
          } else if (error.response.status === 401) {
            errorCallBack("User is no longer active. Please contact admin.");
          } else {
            errorCallBack(error.response.data.message);
          }
        }
      })
      .finally(() => {
     
      
        Intercept.addAuthToken = true;
        
      
      }); 
  }

  static logout(cb) {
    //Clearing in case re-direct url stored
    let temp = localStorage.getItem("Url_redirect")
      ? localStorage.getItem("Url_redirect")
      : null;
    localStorage.clear();
    localStorage.setItem("logoutFlag", "true"); //for checking logout
    
    // assigning redirected URL in after clearing local storage
    if (temp !== null) {
      const url_data_redirect = window.location.href;
      // const x = window.location.pathname;
      const url_data = url_data_redirect.split("/").splice(4).join("/");
      if (url_data !== "login") {
        if (url_data === temp) localStorage.setItem("Url_redirect", temp);
        else localStorage.setItem("Url_redirect", url_data); //if user changes redirected URL after trying once
      } else {
        localStorage.setItem("Url_redirect", temp);
      }
    }
    // localStorage.setItem('order','ORDER')
    Auth.access_token = null;
    Auth.UserInformationAndSettings = {};
 
    
    cb();
  }

  static saveTokenResponse(access_token, refresh_token, expires_in) {
    localStorage.setItem("auth_token", access_token);
    Auth.access_token = access_token;
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem(
      "expires_at",
      moment().add(expires_in, "seconds").valueOf()
    );
    localStorage.setItem("logoutFlag", "false");
  }

  static async isLoggedIn() {
    // console.log('Logged In Check'); //Reload it is hit here first
    const access_token = await Auth.getToken();
    const refreshToken = localStorage.getItem("refresh_token");
    //let loggedIn = access_token != null && access_token !== "" && refreshToken!='';
    let loggedIn = access_token != null || refreshToken != null;

    return loggedIn;
  }

  static loadUserInformationAndSettings(
    successCallback,
    errorCallback,
    loggedInAsClient,
    loggedInAsClientUserId
  ) {
    let requestURL;
    if (loggedInAsClient) {
      requestURL =
        process.env.REACT_APP_API_URL +
        "api/get-loggedin-user-information?loginasClient=" +
        loggedInAsClient +
        "&userId=" +
        loggedInAsClientUserId;
    } else {
      requestURL =
        process.env.REACT_APP_API_URL + "api/get-loggedin-user-information";
    }
    let userInformationPromise = axios.get(requestURL).then((response) => {
      Auth.userInformationAndSettings["user_information"] = response.data;
      // console.log(response.data.store, "response")
      //checked whether store is assigned from DB
      response.data.store.length > 0
        ? localStorage.setItem("storeFlag", "true")
        : localStorage.setItem("storeFlag", "false");

      //console.log(Auth.userInformationAndSettings['user_information']);
    });
    // .catch(error => {

    //   console.log(window.location.href, "not able to log in")
    // })

    Promise.all([userInformationPromise])
      .then((response) => {
        successCallback();
      })
      .catch((error) => {
        errorCallback(error);
      });
  }

  static hasPermission(permissionIdt) {
    let userInformation = Auth.userInformationAndSettings["user_information"];
    if (
      userInformation.is_super_admin === 1 ||
      userInformation.is_super_admin === "1" ||
      permissionIdt === "dashboard"
    ) {
      return true;
    }

    let permissions = userInformation.role?.permissions;
    if (permissions != null) {
      const foundPermission = permissions.find((p) => p.idt === permissionIdt);
      return foundPermission != null;
    }
    return false;
  }

  static hasUserWiseStorePermission(path_id) {
    let userInformation = Auth.userInformationAndSettings["user_information"];
    let storeprm = userInformation.store ? userInformation.store : "";
    if (
      userInformation.is_super_admin === 1 ||
      userInformation.is_super_admin === "1"
    ) {
      return true;
    }
    if (storeprm != null) {
      let foundPermission = storeprm.some(function (el) {
        return el.id === parseInt(path_id);
      });
      return foundPermission;
    }
    return false;
  }

  static async getFreshToken(refresh_token) {
    try {
      Intercept.addAuthToken = false;
      //const response =
      await axios
        .post(process.env.REACT_APP_API_URL + "oauth/token", {
          grant_type: "refresh_token",
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          refresh_token: refresh_token,
          scope: "",
        })
        .then((response) => {
          Intercept.addAuthToken = true;
          const access_token = response["data"]["access_token"];
          const new_refresh_token = response["data"]["refresh_token"];
          const expires_in = response["data"]["expires_in"];

          if (access_token && access_token !== "") {
            Auth.saveTokenResponse(access_token, new_refresh_token, expires_in);
            return Promise.resolve(Auth.access_token);
          } else {
            alert("Error occurred in refreshing auth token");
            return Promise.resolve("");
          }
        })
        .catch(function (error) {
          Auth.logout(function () {});
          history.push("login");
        });
    } catch (error) {
      Intercept.addAuthToken = true;
      if (error.response) {
        //alert('Error occurred in refreshing auth token. Error message: ' + error.response.data.message);
      }
      return Promise.reslove("");
    } finally {
      Intercept.addAuthToken = true;
    }
  }

  static async getToken() {
    const currentlink = window.location.href; // returns the absolute URL of a page
    const access_token = localStorage.getItem("auth_token")
      ? localStorage.getItem("auth_token")
      : null;
    const refresh_token = localStorage.getItem("refresh_token");
    const expires_at = moment(localStorage.getItem("expires_at"), "x");
    if (access_token === null) {
      if (refresh_token === null || expires_at === null) {
        Auth.logout(function () {});

        const isPresent = localStorage.getItem("Url_redirect");
        //URL stored in local storage or not
        if (isPresent === null) {
          const url_data_redirect = window.location.href;
          // const x = window.location.pathname;
          const url_data = url_data_redirect.split("/").splice(4).join("/");

          if (url_data !== "login") {
            localStorage.removeItem("Url_redirect");
            localStorage.setItem("Url_redirect", url_data); //set in local storage
          }
          // console.log(
          //   localStorage.getItem("Url_redirect"),
          //   "localStorage redirect url"
          // );
        }
        history.push("/login");
        return Promise.resolve("");
      } else {
        let fresh_token = await Auth.getFreshToken(refresh_token);
        return Promise.resolve(fresh_token);
      }
    } else {
      // if (moment() > expires_at - 5000) {
      //   let fresh_token = await Auth.getFreshToken(refresh_token);
      //   return Promise.resolve(fresh_token);
      // }

      if (moment() > expires_at) {
        localStorage.removeItem("auth_token");
        if (currentlink.split("/")[4] !== "") {
          Swal.fire({
            icon: "warning",
            title: "Session Expired!",
            showCancelButton: true,
            confirmButtonText: "Reload to continue",
            cancelButtonText: "Logout",
            allowOutsideClick: false,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              window.location.reload();
            } else if (result.isDismissed) {
              Auth.logout(function () {});
              history.push("login");
            }
          });
        }
        //let fresh_token = await Auth.getFreshToken(refresh_token);
        //return Promise.resolve(fresh_token);
        return false;
      } else {
        return Promise.resolve(access_token);
      }
    }
  }

  static forgotPassword(_email, successCallBack, errorCallBack) {
    axios
      .post(process.env.REACT_APP_API_URL + "api/forgotPassword", {
        email: _email,
      })
      .then((response) => {
        const mail_send_status = response["data"]["mail_send_status"];
        if (mail_send_status) {
          Swal.fire({
            icon: "success",
            title: "Email sent!",
            html: "Password reset request was sent successfully. Please check your email to reset your password. ",
          });
          successCallBack();
        } else {
          Swal.fire({
            icon: "Error",
            title: "Server error!",
            html: "Please try again.",
          });
          errorCallBack("Unable to send email");
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            errorCallBack("User does not exists with this email.");
            Swal.fire({
              icon: "error",
              title: "Invalid user!",
              html: "User does not exists with this email.",
            });
          } else {
            errorCallBack(error.response.data.message);
          }
        }
      })
      .finally(() => {});
  }

  static resetPassword(
    _password,
    _confirmpassword,
    _resetToken,
    successCallBack,
    errorCallBack
  ) {
    axios
      .post(process.env.REACT_APP_API_URL + "api/resetPassword", {
        password: _password,
        confirmpassword: _confirmpassword,
        token: _resetToken,
      })
      .then((response) => {
        const passord_reset_status = response["data"]["passord_reset_status"];
        if (passord_reset_status) {
          Swal.fire({
            icon: "success",
            title: "Password reset successfully!",
            html: "Please login with your new password.",
          });
          successCallBack();
        } else {
          errorCallBack("Server error!");
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            errorCallBack("Looks like the token is expired!");
            Swal.fire({
              icon: "error",
              title: "Looks like the token is expired!",
              html: "You have to generate a new reset password link by clicking on the button given below.",
              confirmButtonText: "Forgot Password",
            }).then(function () {
              window.location = process.env.PUBLIC_URL + "/#/forgot-password";
            });
          }
          if (error.response.status === 408) {
            errorCallBack(
              "New password should not match with the previous password."
            );
            Swal.fire({
              icon: "error",
              title:
                "Looks like the new password is same as the previous password",
              html: "You have to provide a new password that should not be same as the previous password.",
              //confirmButtonText: 'Forgot Password',
            }).then(function () {
              //window.location=proces s.env.PUBLIC_URL+"/#/forgot-password"
            });
          } else {
            errorCallBack(error.response.data.message);
          }
        }
      })
      .finally(() => {});
  }

  static sendfireBaseToken(userID) {
    // console.log('insode send firebase local storage item', localStorage.getItem('fbtoken'))
    axios
      .post(process.env.REACT_APP_API_URL + "api/setUserDeviceID", {
        id: userID,
        deviceID: sessionStorage.getItem("fbtoken"),
      })
      .then((response) => {
        return true;
      })
      .catch((error) => {
        return false;
      });
  }

  static getAddressDetails(orderId, token){
    axios.get(
      process.env.REACT_APP_API_URL + "api/update-invalid-address?orderId=" + orderId + "&token=" + token
    ).then( (response) => {
      return response;
    // //  if( this._isMounted){
    //    if (response.data.success) { 
    //      this.setState({
    //       OrderDetails: response.data,
    //       loading: false,
    //     }, () =>console.log(this.state.OrderDetails));
    //   } else{
    //     console.log(response)
    //     Swal.fire({
    //         icon: "error",
    //         title: "Oops!",
    //         html: response.data["message"],
    //       })
        
    //   }
    // }
    })
  }
}
