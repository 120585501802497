import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
//import Notification from './firebaseNotification/Notification';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const ClientLogin = React.lazy(() => import('./views/Stores/ClientLogin'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
// const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const AddressCorrection = React.lazy(() => import('./views/Pages/AddressCorrection/AddressCorrection'));
const ForgotPassword = React.lazy(() => import('./views/Pages/ForgotPassword/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/Pages/ForgotPassword/ResetPassword'));
const AcceptInvitation = React.lazy(() => import('./views/Pages/AcceptInvitation/AcceptInvitation'));
const LoginWith29Next = React.lazy(() => import('./views/Pages/LoginWith29Next/LoginWith29Next'));
// const Test = React.lazy(() => import('./views/Pages/Test/Test'));

class App extends Component {

  render() {
    return (
      <>
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} /> */}
              {/* <Route exact path="/test" name="test" render={props => <Test {...props}/>}/> */}
              <Route exact path="/update-invalid-address/:token" name="Address Correction Page" render={props => <AddressCorrection {...props}/>} />
              <Route exact path="/forgot-password" name="Forget Password Page" render={props => <ForgotPassword {...props}/>} />
              <Route exact path="/verify-login" name="29next" render={props => <LoginWith29Next {...props}/>} /> 
              <Route exact path="/reset/:id" name="Reset Password Page" render={props => <ResetPassword {...props} />} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route exact path="/accept-invitation/:invitationtoken/:iflag?" name="Accept Invitation" render={props => <AcceptInvitation {...props}/>} />
              <Route exact path="/invalid-address" name="Accept Invitation" render={props => <AcceptInvitation {...props}/>} />
              <Route exact path="/login-as-client" name="Client Login" render={props => <ClientLogin {...props}/>} />
              <Route  path="/" name="Home" render={props => <DefaultLayout {...props}/>} />    
               
            </Switch>
          </React.Suspense>
      </HashRouter>
      </>
    );
  }
}

export default App;
