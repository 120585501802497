
import AuthContext from "./AuthContext";
import React, { Component } from "react";
import moment from "moment-timezone";
class AuthContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailError: false,
      //for handling notifications context from "DefaultHeader.js" and "Notifications.js"
      SelectionRange: {
        startDate: null,
        endDate: new Date(""),
        key: "selection",
      },
      activityLogSelectionRange:{
        startDate: null,
        endDate: new Date(""),
        key: "selection",
      },
      ClickbankSelectionRange:{
        startDate: null,
        endDate: new Date(""),
        key: "selection",
      },
      customerActivityLogSelectionRange:{
        startDate: null,
        endDate: new Date(""),
        key: "selection",
      },
      activeAccountID: '',
      notificationReadFlag: '',
      notificationData: [],
      totalTableData:'',
      totalNotificationsData: 0,
      totalUnreadNotificationsData: 0,
      notificationId: -1, //to pass notification id from notification icon list globally
      updateUnreadNotification: this.updateUnreadNotification,
      updateTotalNotificationData: this.updateTotalNotificationData,
      updateContextState: this.updateContextState,
      isAssignedUserRoute: false,
      isOrderRoute: false,
      requestUrl: "",
      //isStoreDropdowntoggled: false,
      acceptInvitationLink: "",
      isSuperAdmin: 0,
      timeZone: "",
      exportStoreValue: "",
      smsGateways: false,
      TP: "",
      smsToken: '',
      exportStoreName: "",
      exportStoreId: "",
      dateFormat: "",
      timeFormat: "",
      keyofproduct: "",
      shipStationStoreId: "",
      activeStoreId: "",
      activeStoreName: "",
      allStore: [],
      loggedInAsClient: 0,
      loggedInAsClientUserId: "",
      loggedInAsClientUserName: "",
      isFormSubmitting: false,
      bulkstore: [],
      SmtpHost: '',
      SmtpHostonCancel: '',
      bulkstoreid: [],
      currentStoreDetails: [],
      logOutHandler: this.logOutHandler,
      whoIsLoggedIn: this.whoIsLoggedIn,
      getDateTimeFormat: this.getDateTimeFormat,
      getTimeFormat: this.getTimeFormat,
      updateDateTimeFormat: this.updateDateTimeFormat,
      profileImage: "",
      csvfile: '',
      assets: '',
      productDescription:'',
      assetDescription:'',
      assetName: '',
      filenameonedit: "",
      profileName: "",
      emailAddresses: [],
      selectedAssetList: [],
      editedfileName: '',
      productList: [],
      productData: [],
      storeList: [],
      storeData: [],
      storeListForProduct:[],
      productName: [],
      export: {
        quickSearch: "",
      },
      user: {
        quickSearch: "",
        roleBasedSearch: "",
      },
      trackingFile: {
        status: "",
        quickSearch: "",
      },
      trackingNumber: {
        quickSearch: "",
        status: ""
      },
      shipstation: {
        quickSearch: "",
        status: ""
      },
      downloadQueue: {
        quickSearch: "",
        status: ""
      },
      role: {
        quickSearch: "",
      },
      carrier: {
        quickSearch: "",
      },
      postage: {
        quickSearch: "",
      },
      store: {
        postage_filter: "",
        store_filter: "",
        shipstation: "",
        storeStatusBasedSearch: '',
        quickSearch: "",
      },
      product: {
        quickSearch: "",
        shipstation_filter: "",
        active: 'active',
        storeBasedSearch:"",
        store_id:""
      },
      productStoreList: {
        quickSearch: "",
       
       
      },
      merchantProduct: {
        quickSearch: ""
      },
      downloadLog: {
        productBasedSearch: "",
        assetBasedSearch: "",
        productName: "",
        assetName: "",
        quickSearch: "",

      },
      order: {
        ordersQuickSearch: "",
        awaitingPaymentOrdersQuickSearch: "",
        onHoldOrdersQuickSearch: "",
        awaitingShipmentOrdersQuickSearch: "",
        // exportOrdersSelectionRange:"",
        delayedShipmentOrdersQuickSearch: "",
        delayedShipmentOrdersStoreName: "",
        // exportOrdersQuickSearch:"",
        // exportOrdersStoreBasedSearch:"",
        // exportOrdersStoreName:"",
        pendingFulfilmentOrdersQuickSearch: "",
        shippedOrdersQuickSearch: "",
        shipmentTrackingOrdersQuickSearch: "",
        cancelledOrdersQuickSearch: "",
        invalidAddressOrdersQuickSearch: "",

        orderStatusBasedSearch: "",

        ordersStoreBasedSearch: "",
        ordersStoreName: "",
        ordersMerchantStoreName: "",
        ordersMerchantStoreNameSearch: "",
        awaitingPaymentOrdersStoreBasedSearch: "",
        awaitingPaymentOrdersStoreName: "",
        onHoldOrdersStoreBasedSearch: "",
        onHoldOrdersStoreName: "",
        awaitingShipmentOrdersStoreBasedSearch: "",
        delayedShipmentOrdersStoreBasedSearch: "",
        awaitingShipmentOrdersStoreName: "",
        pendingFulfilmentOrdersStoreBasedSearch: "",
        pendingFulfilmentOrdersStoreName: "",
        shippedOrdersStoreBasedSearch: "",
        shipmentTrackingOrdersStoreBasedSearch: "",
        shippedOrdersStoreName: "",
        shipmentTrackingOrdersStoreName: "",
        cancelledOrdersStoreBasedSearch: "",
        cancelledOrdersStoreName: "",
        invalidAddressOrdersStoreBasedSearch: "",
        invalidAddressOrdersStoreName: "",

        /*-- Crm Type Based Search --*/
        ordersCrmTypeBasedSearch: "",
        awaitingPaymentOrdersCrmTypeBasedSearch: "",
        awaitingShipmentOrdersCrmTypeBasedSearch: "",
        cancelledOrdersCrmTypeBasedSearch: "",
        invalidAddressOrdersCrmTypeBasedSearch: "",
        onHoldOrdersCrmTypeBasedSearch: "",
        pendingFulfilmentOrdersCrmTypeBasedSearch: "",
        shippedOrdersCrmTypeBasedSearch: "",
        shipmentTrackingOrdersCrmTypeBasedSearch: "",
        selectedStartDate: null,
        selectedEndDate: null,
        SelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },

        ClickbankSelectionRange:{
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
        
        ordersSelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
        // exportOrdersSelectionRange:{
        //   startDate: null,
        //   endDate: new Date(""),
        //   key: "selection",
        // },
        awaitingPaymentOrdersSelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
        onHoldOrdersSelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
        awaitingShipmentOrdersSelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
        delayedShipmentOrdersSelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
        delayedAwaitingShipmentSelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
        pendingFulfilmentOrdersSelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
        shippedOrdersSelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
        shipmentTrackingOrdersSelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
        cancelledOrdersSelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
        invalidAddressOrdersSelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
      },
      activityLog: {
        quickSearch: "",
        SelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
      },
      ClickBankOrders: {
        quickSearch: "",
        storeBasedSearch:"",
        orderStatus:"",
        vendorBasedSearch:"",
        vendor_id:"",
        store_id:'',
        SelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
      },
      customerActivityLog: {
        quickSearch: "",
        SelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
      },
      notifications: {
        quickSearch: "",
        statusBasedSearch: "",
      },
      recordPerPage: 10,
      productAssestDetails: {},
      testProductAsset: {},
    };

  }

  // updateUnreadNotification = (count) => {
  // this.setState({
  //   ...this.state, totalUnreadNotificationsData : count,
  // })
  // }

  // updateTotalNotificationData = (data) => {
  //   this.setState({...this.state, notificationData: data, })
  // }
  updateContextState = (key, value) => {
    return new Promise((resolve) => this.setState({ ...this.state, [key]: value }, resolve))
  }


  /**
   * This function reset all the state variables after a user logout  
  */
  logOutHandler = () => {
    this.setState({
      isAssignedUserRoute: false,
      isOrderRoute: false,
      requestUrl: "",
      // isStoreDropdowntoggled: false,
      acceptInvitationLink: "",
      isSuperAdmin: 0,
      timeZone: "",
      shipStationStoreId: "",
      activeStoreId: "",
      activeStoreName: "",
      allStore: [],
      loggedInAsClient: 0,
      loggedInAsClientUserId: "",
      productDescription:'',
      assetDescription:'',
      loggedInAsClientUserName: "",
      currentStoreDetails: [],
      profileImage: "",
      profileName: "",
      user: {
        quickSearch: "",
        roleBasedSearch: "",
      },
      role: {
        quickSearch: "",
      },
      store: {
        quickSearch: "",
      },
      postage: {
        quickSearch: "",
      },
      shipstation: {
        quickSearch: "",
        status: ""
      },
      product: {
        quickSearch: "",
        shipstation_filter: "",
        active: '',
      },
      productStoreList: {
        quickSearch: "",
       
       
      },

      downloadLog: {
        productBasedSearch: "",
        assetBasedSearch: "",
        productName: "",
        assetName: "",
        quickSearch: ""
      },
      order: {
        ordersQuickSearch: "",
        awaitingPaymentOrdersQuickSearch: "",
        onHoldOrdersQuickSearch: "",
        awaitingShipmentOrdersQuickSearch: "",
        pendingFulfilmentOrdersQuickSearch: "",
        shippedOrdersQuickSearch: "",
        shipmentTrackingOrdersQuickSearch: "",
        cancelledOrdersQuickSearch: "",

        invalidAddressOrdersQuickSearch: "",

        orderStatusBasedSearch: "",

        ordersStoreBasedSearch: "",
        ordersStoreName: "",
        ordersMerchantStoreName: "",
        ordersMerchantStoreNameSearch: "",
        awaitingPaymentOrdersStoreBasedSearch: "",
        delayedShipmentOrdersQuickSearch: "",
        delayedShipmentOrdersStoreName: "",
        // exportOrdersQuickSearch:"",
        // exportOrdersStoreBasedSearch:"",
        // exportOrdersStoreName:"",
        awaitingPaymentOrdersStoreName: "",
        onHoldOrdersStoreBasedSearch: "",
        onHoldOrdersStoreName: "",
        awaitingShipmentOrdersStoreBasedSearch: "",
        delayedShipmentOrdersStoreBasedSearch: "",
        awaitingShipmentOrdersStoreName: "",
        pendingFulfilmentOrdersStoreBasedSearch: "",

        pendingFulfilmentOrdersStoreName: "",
        shippedOrdersStoreBasedSearch: "",
        shipmentTrackingOrdersStoreBasedSearch: "",
        shippedOrdersStoreName: "",
        shipmentTrackingOrdersStoreName: "",
        cancelledOrdersStoreBasedSearch: "",
        cancelledOrdersStoreName: "",
        invalidAddressOrdersStoreBasedSearch: "",
        invalidAddressOrdersStoreName: "",

        /*-- Crm Type Based Search --*/
        ordersCrmTypeBasedSearch: "",
        awaitingPaymentOrdersCrmTypeBasedSearch: "",
        awaitingShipmentOrdersCrmTypeBasedSearch: "",
        cancelledOrdersCrmTypeBasedSearch: "",
        invalidAddressOrdersCrmTypeBasedSearch: "",
        onHoldOrdersCrmTypeBasedSearch: "",
        pendingFulfilmentOrdersCrmTypeBasedSearch: "",
        shippedOrdersCrmTypeBasedSearch: "",
        shipmentTrackingOrdersCrmTypeBasedSearch:"",
        ordersSelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
        awaitingPaymentOrdersSelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },

        onHoldOrdersSelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
        delayedShipmentOrdersSelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
        pendingFulfilmentOrdersSelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
        exportOrdersSelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
        shippedOrdersSelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
        shipmentTrackingOrdersSelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
        cancelledOrdersSelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
        invalidAddressOrdersSelectionRange: {
          startDate: null,
          endDate: new Date(""),
          key: "selection",
        },
      },
      activityLog: {
        quickSearch: "",
      },
      ClickBankOrders: {
        quickSearch: "",
       
      },
      customerActivityLog: {
        quickSearch: "",
      },
      notifications: {
        quickSearch: "",
        statusBasedSearch: "",
      },
    });
  }
  /**
   * This function check if super admin logged in as normal user 
   *
   * @returns {Array} Returns the status(1,0) if the super admin logged in as normal client and that logged in client user id .

  */
  whoIsLoggedIn = () => {
   
    let loggedInAsClient, loggedInAsClientUserId, activeStoreId, activeStoreName,shipStationStoreId;
    if (localStorage.getItem('loggedInAsClient') || localStorage.getItem('loggedInAsClientUserId')) {
      loggedInAsClient = localStorage.getItem('loggedInAsClient');
      loggedInAsClientUserId = localStorage.getItem('loggedInAsClientUserId');
      activeStoreId = localStorage.getItem('activeStoreId');
      activeStoreName = localStorage.getItem('activeStoreName');
      shipStationStoreId = localStorage.getItem('shipstationStoreId');
      this.setState({
        loggedInAsClient: loggedInAsClient,
        loggedInAsClientUserId: loggedInAsClientUserId,
        activeStoreId: activeStoreId,
        activeStoreName: activeStoreName,
        shipStationStoreId:shipStationStoreId
      })
    } else {
      loggedInAsClient = this.state.loggedInAsClient;
      loggedInAsClientUserId = this.state.loggedInAsClientUserId;
      activeStoreId = this.state.activeStoreId;
      activeStoreName = this.state.activeStoreName;
      shipStationStoreId = this.state.shipStationStoreId;
    }

    return [loggedInAsClient, loggedInAsClientUserId, activeStoreId, activeStoreName,shipStationStoreId];
  }

  getDateTimeFormat = (receivedDate, timeRequired) => {
    let requiredDate;
    if (receivedDate === null) {
      receivedDate = new Date();
    }
    if (timeRequired === true) {
      requiredDate = moment.utc(receivedDate, 'YYYY-MM-DD HH:mm:ss').tz(this.state.timeZone).format(this.state.dateFormat + ' ' + this.state.timeFormat);
    } else {
      requiredDate = moment.utc(receivedDate, 'YYYY-MM-DD HH:mm:ss').tz(this.state.timeZone).format(this.state.dateFormat);
    }
    return requiredDate;
  }

  getTimeFormat = (receivedData) => {
    let requiredTime;
    if (receivedData === null) {
      receivedData = new Date();
    }
    requiredTime = moment.utc(receivedData, 'YYYY-MM-DD HH:mm:ss').tz(this.state.timeZone).format(this.state.timeFormat);

    return requiredTime;
  }

  render() {
    return (
      <AuthContext.Provider value={{ state: this.state }}>
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

export default AuthContextProvider;